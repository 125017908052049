import {Breadcrumbs, Btn, H5} from "../../AbstractElements";
import {Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row} from "reactstrap";
import {useState} from "react";
import DatePicker from "react-datepicker";
import {LaporanKeuanganTable} from "../../Components/Tables/LaporanKeuanganTable";

export const ViewsLaporanRekapKeuangan = () => {
    const initialRefreshState = {
        transactionDataTable: false,
    }

    const [refreshState, setRefreshState] = useState(initialRefreshState)

    const refreshAllState = () => {
        setRefreshState({
            ...initialRefreshState,
            transactionDataTable: true
        })
    }

    const handlerSubmitSearchAccount = (e) => {
        e.preventDefault()
        refreshAllState()
    }

    // FOR TABLE
    const [searchDate, setSearchDate] = useState({
        from: new Date().setHours(0, 0, 0, 0),
        to: new Date().setHours(23, 59, 59, 999)
    })

    return (
        <>
            <Breadcrumbs parent="Laporan" title="Laporan Rekap Keuangan" mainTitle="Laporan Rekap Keuangan"/>
            <Container fluid={true} className="datatables">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="pb-0">
                                <Row className="justify-content-between">
                                    <Col md={"auto"}>
                                        <H5>Laporan Keuangan</H5>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={handlerSubmitSearchAccount}>
                                    <Row className="align-items-center m-b-15">
                                        <Col md={5} sm={12}>
                                            <FormGroup>
                                                <Label>Date From</Label>
                                                <DatePicker className="form-control digits"
                                                            onChange={(e) => {
                                                                setSearchDate({
                                                                    ...searchDate,
                                                                    from: e.setHours(0, 0, 0, 0)
                                                                })
                                                            }}
                                                            selected={searchDate.from}/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={5} sm={12}>
                                            <FormGroup>
                                                <Label>Date Until</Label>
                                                <DatePicker className="form-control digits"
                                                            onChange={(e) => {
                                                                setSearchDate({
                                                                    ...searchDate,
                                                                    to: e.setHours(23, 59, 59, 999)
                                                                })
                                                            }}
                                                            selected={searchDate.to}/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={2} sm={12}>
                                            <FormGroup>
                                                <Label>.</Label>
                                                <Btn attrBtn={{
                                                    color: 'primary',
                                                    style: {width: "100%"},
                                                    type: 'submit',
                                                }}>
                                                    Cari
                                                </Btn>
                                            </FormGroup>

                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Container fluid={true} className="datatables">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="pb-0">
                                <Row className="justify-content-between">
                                    <Col md={"auto"}>
                                        <H5>Tabel Laporan Keuangan</H5>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <LaporanKeuanganTable
                                    refresh={refreshState.transactionDataTable}
                                    onRefreshFinish={() => {
                                        setRefreshState(initialRefreshState)
                                    }}
                                    filters={{
                                        transaction_date_range: `${new Date(searchDate.from).toISOString()}|${new Date(searchDate.to).toISOString()}`
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
        
    )

}