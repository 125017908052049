
import DataTable from "react-data-table-component";
import {useEffect, useState} from "react";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Btn} from "../../AbstractElements";
import {CountPaginationOffset} from "../../Utils/pagination";
import {TableActionStyle} from "../../Utils/table";
import SweetAlert from "sweetalert2";
import axios from "axios";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import {API_CONFIGURATOR} from "../../api/service";

export const ConfiguratorDataTable = ({refresh = false, onRefreshFinish, drawerState, setDrawerState}) => {
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        rowsPerPage: 10,
        page: 1,
        totalRows: 10
    })
    const [isLoading, setIsLoading] = useState(false)
    const [filter, setFilter] = useState({
        key: ""
    })

    const columns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: true,
            center: true,
            compact: true,
            serverSort: "id",
        },
        {
            name: 'Key',
            selector: (row) => row.key,
            sortable: true,
            center: true,
        },
        {
            name: 'Action',
            selector: (row) => tableAction(row.id),
            sortable: true,
            center: true,
        },
    ]

    const tableAction = (id) => {
        return (
            <div>
                <span>
                    <Btn attrBtn={{
                        onClick: () => {
                            setDrawerState({
                                ...drawerState,
                                forms: true,
                                editId: id
                            })
                        }, style: TableActionStyle, className: 'btn btn-success btn-xs', type: 'button'
                    }}>Edit</Btn>
                </span>
            </div>
        )
    }

    const handleDelete = (id) => {
        SweetAlert.fire({
            title: 'Delete?',
            html: 'Are you sure want to delete this?',
            icon: 'error',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete',
            reverseButtons: true,
            showCancelButton: true,
        }).then((e) => {
            if (e.isConfirmed) {
                axios.delete(API_CONFIGURATOR + "/?id=" + id).then(r => {
                    if (r.status === 200) {
                        return r.data["data"]
                    }
                }).then(json => {
                    SweetAlert.fire({
                        title: 'Success!',
                        text: 'Data Deletion Completed!',
                        icon: 'success'
                    }).then(() => {
                        loadData()
                    })
                }).catch(() => {
                    SweetAlert.fire({
                        title: 'Failed!',
                        text: 'Data Deletion Failed!',
                        icon: 'error'
                    })
                })
            }
        });
    }

    const loadData = (limit = 10, offset = 0, filter = {}) => {
        setIsLoading(true);
        axios.get(API_CONFIGURATOR + "/?pagination=" + CreatePaginationEncoding(limit, offset) + "&filter=" + CreateFilterEncoding(filter)).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                setData(json.data.length > 0 ? json.data : [])
                setPagination({
                    ...pagination, totalRows: json.count.all
                })
            } else {
                setData([])
                setPagination({
                    ...pagination, totalRows: 0
                })
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        if (refresh === true) {
            loadData(pagination.rowsPerPage, CountPaginationOffset(pagination.page, pagination.rowsPerPage), {
                ...filter
            })
            onRefreshFinish()
        }
    }, [refresh])

    useEffect(() => {
        loadData()
    }, [])

    const onSearchSubmit = (e) => {
        e.preventDefault()
        loadData(pagination.rowsPerPage, 0, {...filter})
    }

    const onSort = (column, sortDir) => {
        console.log(column, sortDir);
    }

    const onPageChange = page => {
        loadData(pagination.rowsPerPage, CountPaginationOffset(page, pagination.rowsPerPage), {...filter})
        setPagination({
            ...pagination, page: page
        })
    };

    const onPaginationRowsChangePage = (newPerPage, page) => {
        loadData(newPerPage, CountPaginationOffset(page, newPerPage))
        setPagination({
            ...pagination, page: page, rowsPerPage: newPerPage
        })
    }

    return <>
        <Col md={12}>
            <Form onSubmit={onSearchSubmit}>
                <Row className="justify-content-start align-items-end">
                    <Col md="auto">
                        <FormGroup className="row">
                            <Label htmlFor="exampleFormControlSelect3">Cari (Key)</Label>
                            <Col sm="12">
                                <Input className="form-control" type="text" placeholder={"Search Here"}
                                       onChange={(e) => {
                                           setFilter({
                                               ...filter,
                                               key: e.target.value,
                                           })
                                       }}/>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md="auto">
                        <Label htmlFor="exampleFormControlSelect3"></Label>
                        <FormGroup className="row">
                            <Btn attrBtn={{color: "primary", className: "m-r-15", type: "submit"}}>Search</Btn>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </Col>

        <Col md={12}>
            <DataTable
                sortServer
                pagination
                paginationServer

                data={data}
                columns={columns}
                progressPending={isLoading}
                onSort={onSort}
                paginationTotalRows={pagination.totalRows}
                onChangeRowsPerPage={onPaginationRowsChangePage}
                onChangePage={onPageChange}
                striped={true}
                center={true}
            />
        </Col>
    </>
}