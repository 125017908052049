import {Table} from "reactstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import {API_ACCOUNT, API_REPORT, API_REPORT_FUNDS_RECAP} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import {parseDate} from "../../Utils/date";
import {CountPaginationOffset} from "../../Utils/pagination";

export const LaporanKeuanganTable = ({refresh = false, onRefreshFinish, filters = {}}) => {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)


    const loadData = () => {
        setIsLoading(true);
        axios.get(API_REPORT_FUNDS_RECAP + "?filter=" + CreateFilterEncoding(filters)).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                setData(json.data.length > 0 ? json.data : [])
            } else {
                setData([])
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        if (refresh === true) {
            loadData()
            onRefreshFinish()
        }
    }, [refresh])

    return (
        <Table>
            <thead>
            <tr>
                <th scope="col">{'Date'}</th>
                <th scope="col">{'Total Debit'}</th>
                <th scope="col">{'Total Credit'}</th>
                <th scope="col">{'Saldo'}</th>
            </tr>
            </thead>
            <tbody>
            {
                data.map((item, i) =>
                    <tr key={i}>
                        <td scope="row">{parseDate(item.transaction_date).toLocaleDateString()}</td>
                        <td scope="row">{item.debit}</td>
                        <td scope="row">{item.credit}</td>
                        <td scope="row">{item.balance}</td>
                    </tr>
                )
            }
            </tbody>
        </Table>
    )
}