
import {Breadcrumbs, Btn, H4, H5} from "../../AbstractElements";
import {Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row} from "reactstrap";
import {useEffect, useRef, useState} from "react";
import {TransactionDataTable} from "../../Components/DataTables/TransactionDataTable";
import DatePicker from "react-datepicker";
import axios from "axios";
import {API_ACCOUNT, API_TRANSACTION_REPORT_BALANCE} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import {Briefcase} from "react-feather";
import CurrencyFormat from "react-currency-format";
import {useReactToPrint} from "react-to-print";
import {Hidden} from "../../Components/Common/Component/Hidden";
import xlsx from "json-as-xlsx"
// import {AccountTransactionReportPrint} from "../../Components/Print/AccountTransactionReportPrint";

export const ViewsLaporanTransaksiNasabah = () => {
    const initialDrawerState = {
        forms: false,
        editId: 0
    }

    const initialRefreshState = {
        transactionDataTable: false,
    }

    const [drawerState, setDrawerState] = useState(initialDrawerState)
    const [refreshState, setRefreshState] = useState(initialRefreshState)

    const closeAllDrawer = () => {
        setDrawerState(initialDrawerState)
    }

    const refreshAllState = () => {
        setRefreshState({
            ...initialRefreshState,
            transactionDataTable: true
        })
    }

    const xlsxSetting = {
        fileName: "MySpreadsheet", // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
        writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
        RTL: false, // Display the columns from right-to-left (the default value is false)
    }

    // FOR DOWNLOAD
    const [trxData, setTrxData] = useState([])
    const handleDownloadTrxData = (e) => {
        e.preventDefault()

        // BUILD DATA
        let sheetMap = new Map()
        trxData.forEach((v, i) => {
            if (sheetMap.get(v.account_number) === undefined || sheetMap.get(v.account_number) === null) {
                sheetMap.set(v.account_number, [v])
            } else {
                const newValue = [...sheetMap.get(v.account_number), v]
                sheetMap.set(v.account_number, newValue)
            }
        })

        let buildedTrxData = []

        buildedTrxData.push({
            sheet: "All",
            columns: [
                { label: "ID", value: "id" },
                { label: "Code", value: "transaction_detail_code" },
                { label: "Tanggal Transaksi", value: (row) => {return row.transaction_date.split(".")[0].replaceAll("T", " ")}, format: "yyyy-mm-dd hh:mm:ss"},
                { label: "No. Rekening", value: "account_number", format: "0"},
                { label: "Debit", value: (row) => {return row.transaction_type === 1 ? row.amount : 0 }},
                { label: "Credit", value: (row) => {return row.transaction_type !== 1 ? row.amount : 0 }},
                { label: "Admin ID", value: "admin_id"},
            ],
            content: [...trxData],
        })

        sheetMap.forEach((v,k) => {
            buildedTrxData.push({
                sheet: `${k}`,
                columns: [
                    { label: "ID", value: "id" },
                    { label: "Code", value: "transaction_detail_code" },
                    { label: "Tanggal Transaksi", value: (row) => {return row.transaction_date !== undefined || row.transaction_date !== null ? row.transaction_date.split(".")[0].replaceAll("T", " ") : ""}, format: "yyyy-mm-dd hh:mm:ss" },
                    { label: "No. Rekening", value: "account_number", format: "0"},
                    { label: "Debit", value: (row) => {return row.transaction_type === 1 ? row.amount : 0 }},
                    { label: "Credit", value: (row) => {return row.transaction_type !== 1 ? row.amount : 0 }},
                    { label: "Admin ID", value: "admin_id"},
                ],
                content: [...v],
            })
        })

        console.log("tda", buildedTrxData)

        // let trx = [{
        //     sheet: "Adults",
        //     columns: [
        //         { label: "ID", value: "id" },
        //         { label: "Code", value: "transaction_detail_code" },
        //         { label: "Tanggal Transaksi", value: "transaction_date" },
        //         { label: "No. Rekening", value: "account_number" },
        //         { label: "Debit", value: (row) => {return row.transaction_type === 1 ? row.amount : 0 }},
        //         { label: "Credit", value: (row) => {return row.transaction_type !== 1 ? row.amount : 0 }},
        //         { label: "Admin ID", value: "admin_id"},
        //     ],
        //     content: [
        //         { user: "Andrea", age: 20, more: { phone: "11111111" } },
        //         { user: "Luis", age: 21, more: { phone: "12345678" } },
        //     ],
        // }]

        xlsx(buildedTrxData, xlsxSetting);
    }

    // FOR TABLE
    const [foundAccountNumber, setFoundAccountNumber] = useState(0)
    const [searchDate, setSearchDate] = useState({
        from: new Date().setHours(0, 0, 0, 0),
        to: new Date().setHours(23, 59, 59, 999)
    })

    // FOR SUM BALANCE
    const [summaryBalance, setSummaryBalance] = useState({
        balance : 0,
        debit : 0,
        credit : 0,
    })

    useEffect(() => {
        axios.get(API_TRANSACTION_REPORT_BALANCE).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                setSummaryBalance({...json.data})
            }
        })
    }, [])

    // FOR SEARCH ACCOUNT FORM
    const initialAccountData = {
        id: 0,
        account_number: 0,
        full_name: "",
        birth_place: "",
        birth_date: new Date(2000, 0, 1, 0, 0, 0, 0).toISOString(),
        address: "",
        marriage_status: 0,
        profession: "",
        citizenship: 0,
        religion: 0
    }

    const [accountData, setAccountData] = useState(initialAccountData)

    const handlerSubmitSearchAccount = (e) => {
        e.preventDefault()

        if (accountData.account_number === 0) {
            return
        }

        axios.get(API_ACCOUNT + "/?pagination=" + CreatePaginationEncoding(1, 0) + "&filter=" + CreateFilterEncoding({})).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                json.data.length > 0 && setAccountData(json.data[0])
                setFoundAccountNumber(accountData.account_number)
            } else {
                setFoundAccountNumber(0)
                setAccountData({
                    ...initialAccountData,
                    account_number: accountData.account_number
                })
            }
        })
    }

    // FOR PRINT
    const printRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        pageStyle: `
        @page {
            size: portrait;
          }
        `
    });

    const [printData, setPrintData] = useState([])

    const handleTransactionPrint = (data) => {
        setPrintData([...data])
    }

    useEffect(() => {
        if (printData.length > 0) {
            handlePrint()
        }
    }, [printData])



    return (
        <>
            <Breadcrumbs parent="Laporan" title="Laporan Transaksi Nasabah" mainTitle="Laporan Transaksi Nasabah"/>
            <Container fluid={true} className="datatables">
                <Row>
                    <Col md={4}>
                        <Card className="o-hidden border-0">
                            <CardBody className={'bg-primary'}>
                                <div className="media static-top-widget">
                                    <div className="align-self-center text-center">
                                        <Briefcase/>
                                    </div>
                                    <div className="media-body">
                                        <span className="m-0">Total Setor</span>
                                        <CurrencyFormat value={summaryBalance.credit}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'Rp '} renderText={value => <H4
                                            attrH4={{className: 'mb-0 counter'}}>{value}</H4>}/>


                                        <Briefcase className="icon-bg"/>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="o-hidden border-0">
                            <CardBody className={'bg-primary'}>
                                <div className="media static-top-widget">
                                    <div className="align-self-center text-center">
                                        <Briefcase/>
                                    </div>
                                    <div className="media-body">
                                        <span className="m-0">Total Ambil</span>
                                        <CurrencyFormat value={summaryBalance.debit}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'Rp '} renderText={value => <H4
                                            attrH4={{className: 'mb-0 counter'}}>{value}</H4>}/>


                                        <Briefcase className="icon-bg"/>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="o-hidden border-0">
                            <CardBody className={'bg-secondary'}>
                                <div className="media static-top-widget">
                                    <div className="align-self-center text-center">
                                        <Briefcase/>
                                    </div>
                                    <div className="media-body">
                                        <span className="m-0">Saldo</span>
                                        <CurrencyFormat value={summaryBalance.balance}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'Rp '} renderText={value => <H4
                                            attrH4={{className: 'mb-0 counter'}}>{value}</H4>}/>


                                        <Briefcase className="icon-bg"/>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="pb-0">
                                <Row className="justify-content-between">
                                    <Col md={"auto"}>
                                        <H5>Filter</H5>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={handlerSubmitSearchAccount}>
                                    <Row className="align-items-center m-b-15">
                                        <Col md={6} sm={12}>
                                            <FormGroup>
                                                <Label>Date From</Label>
                                                <DatePicker className="form-control digits"
                                                            onChange={(e) => {
                                                                setSearchDate({
                                                                    ...searchDate,
                                                                    from: e.setHours(0, 0, 0, 0)
                                                                })
                                                            }}
                                                            selected={searchDate.from}/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <FormGroup>
                                                <Label>Date Until</Label>
                                                <DatePicker className="form-control digits"
                                                            onChange={(e) => {
                                                                setSearchDate({
                                                                    ...searchDate,
                                                                    to: e.setHours(23, 59, 59, 999)
                                                                })
                                                            }}
                                                            selected={searchDate.to}/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <Btn attrBtn={{
                                                color: 'primary',
                                                style: {width: "100%"},
                                                type: 'submit',
                                            }}>
                                                Cari
                                            </Btn>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <Btn attrBtn={{
                                                color: 'secondary',
                                                style: {width: "100%"},
                                                onClick: (e) => {
                                                    new Promise(() => {
                                                        handleDownloadTrxData(e);
                                                    }).then(() => {
                                                        // xlsx(trxData, xlsxSetting);
                                                    })
                                                }
                                            }}>
                                                Download Rekap
                                            </Btn>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Container fluid={true} className="datatables">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="pb-0">
                                <Row className="justify-content-between">
                                    <Col md={"auto"}>
                                        <H5>Tabel Transaksi</H5>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <TransactionDataTable onPrint={handleTransactionPrint} id={drawerState.editId} open={drawerState.forms}
                                                      forceLoad={true}
                                                      onClose={closeAllDrawer} accountNumber={foundAccountNumber}
                                                      searchDate={searchDate} visibleAccountNumber={true} loadFinishCallback={(data) => setTrxData(data)}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Hidden>
                {/*<AccountTransactionReportPrint ref={printRef} headerData={{*/}
                {/*    dateFrom : new Date(searchDate.from).toLocaleDateString("en-GB", {timeZone: "Asia/Jakarta"}),*/}
                {/*    dateTo: new Date(searchDate.to).toLocaleDateString("en-GB", {timeZone: "Asia/Jakarta"}),*/}
                {/*    balance: summaryBalance.balance*/}
                {/*}} data={printData}/>*/}
            </Hidden>
        </>
    )

}