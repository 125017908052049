
import {Breadcrumbs, Btn, H5} from "../../AbstractElements";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {useState} from "react";
import {PenggunaDataTable} from "../../Components/DataTables/PenggunaDataTable";
import {PenggunaFormDrawer} from "../../Components/Drawers/PenggunaFormDrawer";


export const ViewsSettingPengguna = () => {
    const initialDrawerState = {
        forms: false,
        editId: 0
    }

    const initialRefreshState = {
        penggunaDataTable: false,
    }

    const [drawerState, setDrawerState] = useState(initialDrawerState)
    const [refreshState, setRefreshState] = useState(initialRefreshState)

    const closeAllDrawer = () => {
        refreshAllState()
        setDrawerState(initialDrawerState)
    }

    const refreshAllState = () => {
        setRefreshState({
            ...initialRefreshState,
            penggunaDataTable: true
        })
    }
    return (
        <>
            <Breadcrumbs parent="Nasabah" title="Manage Pengguna" mainTitle="Manage Pengguna"/>
            <Container fluid={true} className="datatables">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="pb-0">
                                <Row className="justify-content-between">
                                    <Col md={"auto"}>
                                        <H5>Manage Pengguna</H5>
                                    </Col>
                                    <Col md={"auto"}>
                                        <Btn attrBtn={{
                                            color: "secondary", className: "m-r-15", onClick: (e) => {
                                                setDrawerState({...drawerState, forms: true})
                                            }
                                        }}>Pengguna Baru</Btn>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <PenggunaDataTable drawerState={drawerState} setDrawerState={setDrawerState}
                                                   refresh={refreshState.penggunaDataTable}
                                                   onRefreshFinish={() => setRefreshState(initialRefreshState)}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <PenggunaFormDrawer id={drawerState.editId} open={drawerState.forms} onClose={closeAllDrawer}/>
        </>
    )

}