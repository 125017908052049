// const HOST = `http://localhost:4488`
const HOST = `https://api.kbihu-safara.com`

export const API_DATASTORE = `${HOST}/datastore`

export const API_TRANSACTION = `${API_DATASTORE}/transaction`

export const API_TRANSACTION_REPORT = `${API_TRANSACTION}/report`

export const API_TRANSACTION_REPORT_BALANCE = `${API_TRANSACTION_REPORT}/balance`

export const API_USER = `${API_DATASTORE}/user`

export const API_USER_CURRENT = `${API_USER}/current`

export const API_USER_AUTHORIZE = `${API_USER}/authorize`

export const API_ACCOUNT = `${API_DATASTORE}/account`

export const API_REPORT = `${API_DATASTORE}/report`
export const API_REPORT_FUNDS_RECAP = `${API_REPORT}/funds-recap`

export const API_ACCOUNT_BALANCE = `${API_ACCOUNT}/balance`

export const API_CONFIGURATOR = `${API_DATASTORE}/configurator`