
import Drawer from "react-modern-drawer";
import {useEffect, useRef, useState} from "react";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Btn, H4} from "../../AbstractElements";
import {handlerSetFormNumeric} from "../../Utils/forms";
import {useReactToPrint} from "react-to-print";
import {Hidden} from "../Common/Component/Hidden";
import axios from "axios";
import {API_TRANSACTION_REPORT} from "../../api/service";
import {TransactionBookReportPrint} from "../Print/TransactionBookReportPrint";

export const BookPrintFormDrawer = ({accountNumber, id, open, onClose, direction = "right", className}) => {
    const initialData = {
        account_number: 0,
        last_ref_id: 1,
        last_book_line: 0
    }

    const [data, setData] = useState(initialData)

    const [printData, setPrintData] = useState([])

    const printRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        pageStyle: `
        @page {
            size: portrait;
            height: 180mm;
          }
        `
    });

    useEffect(() => {
        setData({
            ...data,
            account_number: accountNumber
        })
    }, [accountNumber])

    const loadPrintData = () => {
        axios.get(API_TRANSACTION_REPORT + `/${data.account_number}/${data.last_ref_id}`).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                json.data.length > 0 && setPrintData(json.data)
            }
        })
    }

    const handlerSubmit = (e) => {
        e.preventDefault()
        loadPrintData()
    }

    return (
        <Drawer
            open={open}
            onClose={onClose}
            direction={direction}
            className={'creation-drawer ' + className}
        >
            <div className="m-b-30">
                <H4>{id !== 0 ? "Edit" : "Buat"} Transaksi</H4>
                <hr/>
            </div>
            <Form className="form theme-form" onSubmit={handlerSubmit}>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>No. Rekening</Label>
                            <Input required name="account_number" value={data.account_number}
                                   onChange={(e) => handlerSetFormNumeric(e, data, setData)}
                                   disabled={true}
                                   className="form-control btn-square" type="number"
                                   placeholder="Type Something"/>
                        </FormGroup>
                        <FormGroup>
                            <Label>#Ref Terakhir</Label>
                            <Input required name="last_ref_id" value={data.last_ref_id}
                                   onChange={(e) => handlerSetFormNumeric(e, data, setData)}
                                   className="form-control btn-square" type="number"
                                   placeholder="Type Something"/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Baris Buku Terakhir</Label>
                            <Input required name="last_book_line" value={data.last_book_line}
                                   onChange={(e) => handlerSetFormNumeric(e, data, setData)}
                                   className="form-control btn-square" type="number"
                                   placeholder="Type Something"/>
                        </FormGroup>
                    </Col>
                </Row>
                {data.account_number !== 0 &&
                    <Btn attrBtn={{color: 'secondary', className: 'm-r-15', type: "submit"}}>Load Data</Btn>}

                {printData.length > 0 &&
                    <Btn attrBtn={{
                        color: 'secondary', className: 'm-r-15', type: "button", onClick: () => {
                            handlePrint()
                        }
                    }}>Cetak
                        Buku</Btn>}
            </Form>
            <Hidden>
                <TransactionBookReportPrint ref={printRef} data={printData} startingLine={data.last_book_line}/>
            </Hidden>
        </Drawer>
    )
}