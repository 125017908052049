import {useEffect, useState} from "react";
import Select from "react-select";

export const TransactionDebitDetailCodeSelect = ({onChange, refresh = false, selected, onRefreshFinish}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [options, setOptions] = useState([
        {
            value: 11,
            label: "Setoran Awal"
        },
        {
            value: 12,
            label: "Setoran"
        },
        {
            value: 13,
            label: "Lainnya"
        },
    ])
    const [selectedOptions, setSelectedOptions] = useState([])

    useEffect(() => {
        if (selected !== undefined) {
            setSelectedOptions(options.find((e) => e.value === selected))
        }
    }, [selected, options])

    useEffect(() => {
        if (refresh === true) {
            setSelectedOptions([])
            onRefreshFinish()
        }
    }, [refresh])


    const handleOnChange = (newValue) => {
        setSelectedOptions(newValue)
        onChange(newValue)
    }


    return (
        <>
            <Select
                getOptionLabel={(opt) => `${opt.label}`}
                value={selectedOptions}
                getOptionValue={(opt) => `${opt.label}`}
                onChange={(newValue, m) => {
                    handleOnChange(newValue)
                }}
                options={options}
                loadOption={isLoading}
                className="js-example-basic-single col-sm-12"
            />
        </>
    )
}
