import React, {useEffect} from 'react';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import {routes} from './Routes';
import AppLayout from '../Layout/Layout';
import {useCookies} from "react-cookie";

const LayoutRoutes = () => {

    const [cookies, setCookie, removeCookie] = useCookies();

    const navigate = useNavigate()

    useEffect(() => {
        const JWT_TOKEN = cookies['XJ-AUTH']

        if (JWT_TOKEN === undefined || JWT_TOKEN === null || JWT_TOKEN === "") {
            navigate(`${process.env.PUBLIC_URL}/login`)
        }
    }, [])


    return (
        <>
            <Routes>
                {routes.map(({path, Component}, i) => (
                    <Route element={<AppLayout/>} key={i}>
                        <Route path={'/'} element={<Navigate to={`${process.env.PUBLIC_URL}/transaction/debit`}/>}/>
                        <Route path={path} element={Component}/>
                    </Route>
                ))}
            </Routes>
        </>
    );
};

export default LayoutRoutes;