import React from "react";

export const BookCoverPrint = React.forwardRef((props, ref) => {
    let {data} = props


    return (
        <div className="book-cover-print" ref={ref}>
            <table>
                <tr>
                    <td style={{
                        width: "4.5cm",
                    }}>{data.office_name}
                    </td>
                    <td style={{
                        width: "7.25cm",
                    }}></td>
                </tr>
                <tr>
                    <td style={{
                        width: "4.5cm",
                    }}>
                    </td>
                    <td style={{
                        width: "7.25cm",
                    }}>{data.created_at}</td>
                </tr>
            </table>
            <table>
                <tr>
                    <td style={{
                        width: "11cm",
                    }}>{data.account_number}
                    </td>
                </tr>
                <tr>
                    <td style={{
                        width: "11cm",
                    }}>{data.full_name}
                    </td>
                </tr>
                <tr>
                    <td style={{
                        width: "11cm",
                        wordWrap: "break-word"
                    }}>{data.address}
                    </td>
                </tr>
            </table>
        </div>
    )
})