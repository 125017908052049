
import {Breadcrumbs, Btn, H5} from "../../AbstractElements";
import {Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {useState} from "react";
import {TransactionDataTable} from "../../Components/DataTables/TransactionDataTable";
import {handlerSetFormNumeric} from "../../Utils/forms";
import axios from "axios";
import {API_ACCOUNT} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import {TransactionFormDrawer} from "../../Components/Drawers/TransactionFormDrawer";

export const ViewsTransaksiAmbil = () => {
    const initialDrawerState = {
        forms: false,
        editId: 0
    }

    const initialRefreshState = {
        transactionDataTable: false,
    }

    const [drawerState, setDrawerState] = useState(initialDrawerState)
    const [refreshState, setRefreshState] = useState(initialRefreshState)

    const closeAllDrawer = () => {
        setDrawerState(initialDrawerState)
        setRefreshState({
            ...refreshState,
            transactionDataTable: true
        })
    }

    const refreshAllState = () => {
        setRefreshState({
            ...initialRefreshState,
            transactionDataTable: true
        })
    }

    // FOR TABLE
    const [foundAccountNumber, setFoundAccountNumber] = useState(0)
    const [searchDate, setSearchDate] = useState({
        from: new Date().setHours(0, 0, 0, 0),
        to: new Date().setHours(23, 59, 59, 999)
    })


    // FOR SEARCH ACCOUNT FORM
    const initialAccountData = {
        id: 0,
        account_number: 0,
        full_name: "",
        birth_place: "",
        birth_date: new Date(2000, 0, 1, 0, 0, 0, 0).toISOString(),
        address: "",
        marriage_status: 0,
        profession: "",
        citizenship: 0,
        religion: 0
    }

    const [accountData, setAccountData] = useState(initialAccountData)

    const handlerSubmitSearchAccount = (e) => {
        e.preventDefault()

        if (accountData.account_number === 0) {
            return
        }

        axios.get(API_ACCOUNT + "/?pagination=" + CreatePaginationEncoding(1, 0) + "&filter=" + CreateFilterEncoding({
            account_number: accountData.account_number
        })).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                json.data.length > 0 && setAccountData(json.data[0])
                setFoundAccountNumber(accountData.account_number)
            } else {
                setFoundAccountNumber(0)
                setAccountData({
                    ...initialAccountData,
                    account_number: accountData.account_number
                })
            }
        })
    }

    return (
        <>
            <Breadcrumbs parent="Transaksi" title="Transaksi Ambil" mainTitle="Transaksi Ambil"/>
            <Container fluid={true} className="datatables">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="pb-0">
                                <Row className="justify-content-between">
                                    <Col md={"auto"}>
                                        <H5>Cari Nasabah</H5>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={handlerSubmitSearchAccount}>
                                    <FormGroup>
                                        <Label>No. Rekening</Label>
                                        <Row className="align-items-center">
                                            <Col md={8} sm={12}>
                                                <Input required name="account_number" value={accountData.account_number}
                                                       onChange={(e) => handlerSetFormNumeric(e, accountData, setAccountData)}
                                                       className="form-control btn-square" type="number"
                                                       placeholder="Type Something"/>
                                            </Col>
                                            <Col md={4} sm={12}>
                                                <Btn attrBtn={{
                                                    color: 'secondary',
                                                    style: {width: "100%"},
                                                    type: 'submit',
                                                }}>
                                                    Cari
                                                </Btn>
                                            </Col>
                                        </Row>
                                    </FormGroup>

                                    {
                                        accountData.full_name !== "" &&
                                        <>
                                            <FormGroup>
                                                <Label>Nama Lengkap</Label>
                                                <Input required name="full_name" value={accountData.full_name}
                                                       disabled={true}
                                                       className="form-control btn-square" type="text"
                                                       placeholder="Type Something"/>
                                            </FormGroup>
                                            <Btn attrBtn={{
                                                color: 'primary',
                                                style: {width: "100%"},
                                                type: 'button',
                                                onClick: () => {
                                                    setDrawerState({
                                                        ...drawerState,
                                                        forms: true
                                                    })
                                                }
                                            }}>
                                                Lakukan Ambil Dana
                                            </Btn>
                                        </>
                                    }
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Container fluid={true} className="datatables">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="pb-0">
                                <Row className="justify-content-between">
                                    <Col md={"auto"}>
                                        <H5>Tabel Mutasi</H5>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <TransactionDataTable id={drawerState.editId} open={drawerState.forms}
                                                      onClose={closeAllDrawer} accountNumber={foundAccountNumber}
                                                      searchDate={searchDate} numberOfTransaction={10}
                                                      refresh={refreshState.transactionDataTable}
                                                      onRefreshFinish={() => {
                                                          setRefreshState({
                                                              ...refreshState,
                                                              transactionDataTable: false
                                                          })
                                                      }}
                                                      allowValidationPrint={true}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <TransactionFormDrawer id={drawerState.editId} open={drawerState.forms} onClose={closeAllDrawer}
                                   initialReplacementData={{
                                       account_number: foundAccountNumber,
                                       transaction_type: 2
                                   }}/>
        </>
    )

}