
import React, {Fragment, useEffect, useState} from 'react';
import {Settings} from 'react-feather';
import {H6, Image, P} from '../../AbstractElements';
import man from '../../assets/images/dashboard/1.png';
import {useCookies} from "react-cookie";
import {decodeToken} from "react-jwt"

const Profile = () => {
    const [userData, setUserData] = useState({
        full_name: "",
        role: 0
    })
    const [cookies, setCookie, removeCookie] = useCookies();
    const roleString = ["", "Owner", "Admin"]

    useEffect(() => {
        const JWT_TOKEN = cookies['XJ-AUTH']
        const decodedToken = decodeToken(JWT_TOKEN)

        setUserData({
            ...userData,
            ...decodedToken
        })
    }, [])

    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <a className="setting-primary" href="#javascript">
                    <Settings/>
                </a>
                {/*<Link to={`${process.env.PUBLIC_URL}/app/users/userProfile`}>*/}
                <Image attrImage={{
                    className: 'img-90 rounded-circle',
                    src: man,
                    alt: ''
                }}/>
                <H6 attrH6={{className: 'mt-3 f-14 f-w-600'}}>{userData.full_name}</H6>
                {/*</Link>*/}
                <P attrPara={{className: 'mb-0 font-roboto'}}>{roleString[userData.role]}</P>
                {/*<UL attrUL={{ className: 'flex-row simple-list' }}>*/}
                {/*    <LI><span><span className="counter"></span>6</span>*/}
                {/*        <P>Course</P>*/}
                {/*    </LI>*/}
                {/*    <LI><span><span className="counter"></span>100</span>*/}
                {/*        <P>Exam</P>*/}
                {/*    </LI>*/}
                {/*    <LI><span>30</span>*/}
                {/*        <P>Certificate</P>*/}
                {/*    </LI>*/}
                {/*</UL>*/}
            </div>
        </Fragment>
    );
};

export default Profile;