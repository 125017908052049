
import Drawer from "react-modern-drawer";
import {useEffect, useRef, useState} from "react";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Btn, H4} from "../../AbstractElements";
import {handlerSetFormNumeric} from "../../Utils/forms";
import {TransactionDebitDetailCodeSelect} from "../Select/TransactionDebitDetailCodeSelect";
import CurrencyInput from "react-currency-input-field";
import {TransactionCreditDetailCodeSelect} from "../Select/TransactionCreditDetailCodeSelect";
import {useReactToPrint} from "react-to-print";
import axios from "axios";
import {API_ACCOUNT_BALANCE, API_TRANSACTION} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import SweetAlert from "sweetalert2";
import {Hidden} from "../Common/Component/Hidden";
import {TransactionValidationPrint} from "../Print/TransactionValidationPrint";
import {decodeToken} from "react-jwt";
import {useCookies} from "react-cookie";

export const TransactionFormDrawer = ({id, open, onClose, direction = "right", className, initialReplacementData}) => {
    const initialData = {
        id: 0,
        account_number: 0,
        transaction_type: 0,
        transaction_detail_code: 12,
        amount: 1,
        admin_id: 0,
    }
    const [data, setData] = useState(initialData)
    const [currentBalance, setCurrentBalance] = useState(0)
    const [cookies, setCookie, removeCookie] = useCookies();

    const [printData, setPrintData] = useState({
        reference_number: "N/A",
        date: "N/A",
        account_number: "N/A",
        teller_id: "N/A",
        transaction_type: 0,
        amount: ""
    })

    const printRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        pageStyle: `
        @page {
            size: portrait;
          }
        `
    });

    useEffect(() => {
        if (initialReplacementData !== undefined) {
            setData({
                ...initialData,
                ...initialReplacementData
            })
        }
    }, [initialReplacementData])

    useEffect(() => {
        if (id !== 0) {
            axios.get(API_TRANSACTION + "/?pagination=" + CreatePaginationEncoding(1, 0) + "&filter=" + CreateFilterEncoding({
                id: id
            })).then(r => {
                if (r.status === 200) {
                    return r.data["data"]
                }
            }).then(json => {
                if (json.data) {
                    json.data.length > 0 && setData(json.data[0])
                }
            })
        } else {
            setData(initialData)
        }
    }, [id])

    useEffect(() => {
        loadBalance()
    }, [data.account_number])

    const handlerSubmit = (e) => {
        e.preventDefault()

        const JWT_TOKEN = cookies['XJ-AUTH']
        const decodedToken = decodeToken(JWT_TOKEN)

        if (id === 0) {
            axios.post(API_TRANSACTION + "/", {...data, admin_id: decodedToken["id"]}).then(r => {
                if (r.status === 200) {
                    SweetAlert.fire({
                        title: 'Success!',
                        text: 'Data Saving Completed!',
                        icon: 'success',
                        showCancelButton: true,
                        cancelButtonText: "Cetak Validasi"
                    }).then((resp) => {
                        if (resp.isConfirmed) {
                            setData({
                                ...initialData,
                                ...initialReplacementData
                            })
                            onClose()
                        } else {
                            setPrintData({
                                reference_number: `#${r.data.data.ref[0]}`,
                                date: new Date().toISOString(),
                                account_number: initialReplacementData.account_number,
                                teller_id: decodedToken["id"],
                                transaction_type: data.transaction_type,
                                amount: `${data.amount}`
                            })
                            handlePrint()
                            onClose()
                        }
                        loadBalance()
                    })
                } else {
                    throw r
                }
            }).catch((err) => {
                SweetAlert.fire({
                    title: 'Failed!',
                    text: 'Data Saving Failed!',
                    icon: 'error'
                })
            })
        } else {
            axios.put(API_TRANSACTION + "/", {...data, id: id}).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({
                        title: 'Success!',
                        text: 'Data Saving Completed!',
                        icon: 'success'
                    }).then(() => {
                        onClose()
                    })
                } else {
                    throw r
                }
            }).catch((err) => {
                SweetAlert.fire({
                    title: 'Failed!',
                    text: 'Data Saving Failed!',
                    icon: 'error'
                })
            })
        }

    }

    const loadBalance = () => {
        if (data.account_number !== 0) {
            axios.get(API_ACCOUNT_BALANCE + `/${data.account_number}`).then(r => {
                if (r.status === 200) {
                    return r.data["data"]
                }
            }).then(json => {
                json.balance && setCurrentBalance(json.balance)
            })
        } else {
            setCurrentBalance(0)
        }
    }

    return (
        <Drawer
            open={open}
            onClose={onClose}
            direction={direction}
            className={'creation-drawer ' + className}
        >
            <div className="m-b-30">
                <H4>{id !== 0 ? "Edit" : "Buat"} Transaksi</H4>
                <hr/>
            </div>
            <Form className="form theme-form" onSubmit={handlerSubmit}>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>No. Rekening</Label>
                            <Input required name="account_number" value={data.account_number}
                                   onChange={(e) => handlerSetFormNumeric(e, data, setData)}
                                   disabled={true}
                                   className="form-control btn-square" type="number"
                                   placeholder="Type Something"/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Saldo Saat ini</Label>
                            <CurrencyInput
                                disabled={true}
                                placeholder="Please enter a number"
                                className="form-control btn-square"
                                defaultValue={0}
                                decimalsLimit={2}
                                value={currentBalance}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Jumlah {data.transaction_type === 1 ? "Setoran" : "Penarikan"}</Label>
                            <CurrencyInput
                                placeholder="Please enter a number"
                                className="form-control btn-square"
                                defaultValue={1}
                                decimalsLimit={2}
                                value={data.amount}
                                onValueChange={(value, name) => {
                                    if (parseInt(value) <= currentBalance) {
                                        setData({
                                            ...data,
                                            amount: parseInt(value)
                                        })
                                    } else {
                                        setData({
                                            ...data,
                                            amount: data.transaction_type === 1 ? parseInt(value) : currentBalance
                                        })
                                    }
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Deskripsi</Label>
                            {data.transaction_type === 1 ?
                                <TransactionDebitDetailCodeSelect selected={data.transaction_detail_code}
                                                                  onChange={(sOpt) => {
                                                                      setData({
                                                                          ...data,
                                                                          transaction_detail_code: sOpt.value
                                                                      })
                                                                  }}/>
                                : <TransactionCreditDetailCodeSelect selected={data.transaction_detail_code}
                                                                     onChange={(sOpt) => {
                                                                         setData({
                                                                             ...data,
                                                                             transaction_detail_code: sOpt.value
                                                                         })
                                                                     }}/>

                            }

                        </FormGroup>
                    </Col>
                </Row>
                <Btn attrBtn={{
                    color: 'primary',
                    className: 'm-r-15',
                    type: 'submit',
                }}
                >Submit</Btn>
                <Btn attrBtn={{color: 'light', onClick: () => onClose()}}>Cancel</Btn>
            </Form>
            <Hidden>
                <TransactionValidationPrint ref={printRef} data={printData}/>
            </Hidden>
        </Drawer>
    )
}