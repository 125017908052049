import DataTable from "react-data-table-component";
import {useEffect, useRef, useState} from "react";
import {Col} from "reactstrap";
import {CountPaginationOffset} from "../../Utils/pagination";
import axios from "axios";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import {API_TRANSACTION} from "../../api/service";
import {parseDate} from "../../Utils/date";
import {Btn} from "../../AbstractElements";
import {TableActionStyle} from "../../Utils/table";
import {TransactionValidationPrint} from "../Print/TransactionValidationPrint";
import {Hidden} from "../Common/Component/Hidden";
import {useReactToPrint} from "react-to-print";

export const TransactionDataTable = ({
                                         refresh = false,
                                         onRefreshFinish,
                                         drawerState,
                                         setDrawerState,
                                         accountNumber = 0,
                                         forceLoad = false,
                                         searchDate,
                                         numberOfTransaction = 2147483647,
                                         allowValidationPrint = false,
                                         visibleAccountNumber = false,
                                        loadFinishCallback = () => {},
                                     }) => {
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        rowsPerPage: 10,
        page: 1,
        totalRows: 10
    })
    const [isLoading, setIsLoading] = useState(false)
    const [filter, setFilter] = useState({
        account_number: 0,
        transaction_date_range: '',
        sort: {
            id: "desc"
        }
    })

    const transactionTypeString = ["", "DB", "CR"]

    const printRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        pageStyle: `
        @page {
            size: portrait;
          }
        `
    });

    const [printData, setPrintData] = useState({
        reference_number: "N/A",
        date: "N/A",
        account_number: "N/A",
        teller_id: "N/A",
        transaction_type: 0,
        amount: ""
    })

    const [columns, setColumns] = useState([
        {
            name: 'Tanggal',
            selector: (row) => `${parseDate(row.transaction_date).toLocaleString('en-GB', {timeZone: "UTC"})}`,
            sortable: true,
            center: true,
            compact: true,
            serverSort: "id",
        },
        {
            name: 'Jenis',
            selector: (row) => transactionTypeString[row.transaction_type],
            sortable: true,
            center: true,
        },
        {
            name: 'Jumlah',
            selector: (row) => `${row.amount}`,
            sortable: true,
            center: true,
        },
    ])

    useEffect(() => {
        let newColumn = [{
            name: 'Tanggal',
            selector: (row) =>
                `${parseDate(row.transaction_date)
                    .toLocaleString("en-GB",
                        {timeZone: "UTC"})}`,
            sortable: true,
            center: true,
            compact: true,
            serverSort: "id",
        },
            {
                name: 'Jenis',
                selector: (row) => transactionTypeString[row.transaction_type],
                sortable: true,
                center: true,
            },
            {
                name: 'Jumlah',
                selector: (row) => `${row.amount}`,
                sortable: true,
                center: true,
            },]

        if (allowValidationPrint) {
            newColumn = [...newColumn, {
                name: '',
                selector: (row) => tableAction(row),
                sortable: true,
                center: true,
            }]
        }

        if (visibleAccountNumber) {
            newColumn = [{
                name: 'No. Rek',
                selector: (row) => row.account_number,
                sortable: true,
                center: true,
            }, ...newColumn]
        }
        setColumns(newColumn)
    }, [allowValidationPrint, visibleAccountNumber])


    useEffect(() => {
        if (printData.reference_number !== "N/A") {
            handlePrint()
        }
    }, [printData])

    const tableAction = (row) => {
        return (
            <div>
                    <span>
                        <Btn attrBtn={{
                            onClick: () => {
                                setPrintData({
                                    reference_number: `#${row.id}`,
                                    date: parseDate(row.transaction_date).toISOString(),
                                    account_number: row.account_number,
                                    teller_id: "123",
                                    transaction_type: row.transaction_type,
                                    amount: `${row.amount}`
                                })
                            },
                            style: TableActionStyle,
                            className: 'btn btn-primary btn-xs',
                            type: 'button'
                        }}>Cetak Validasi</Btn>
                    </span>
            </div>
        )
    }

    const loadData = (limit = 10, offset = 0, filter = {}) => {
        setIsLoading(true);
        axios.get(API_TRANSACTION + "/?pagination=" + CreatePaginationEncoding(limit, offset) + "&filter=" + CreateFilterEncoding(filter)).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                setData(json.data.length > 0 ? json.data : [])
                setPagination({
                    ...pagination, totalRows: json.count.all
                })
                loadFinishCallback(json.data.length > 0 ? json.data : [])
            } else {
                setData([])
                setPagination({
                    ...pagination, totalRows: 0
                })
                loadFinishCallback([])
            }
        }).catch((err) => {
            console.log("ERR", err)
        }).finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        if (refresh === true) {
            loadData(pagination.rowsPerPage, CountPaginationOffset(pagination.page, pagination.rowsPerPage), {
                account_number: accountNumber,
                sort: {
                    id: "desc"
                }
            })
            onRefreshFinish()
        }
    }, [refresh])

    useEffect(() => {
        if (accountNumber !== 0 || forceLoad) {
            setFilter({
                account_number: accountNumber,
                transaction_date_range: `${new Date(searchDate.from).toISOString()}|${new Date(searchDate.to).toISOString()}`,
                sort: {
                    id: "desc"
                }
            })

            loadData(pagination.rowsPerPage, 0, {
                account_number: accountNumber,
                transaction_date_range: `${new Date(searchDate.from).toISOString()}|${new Date(searchDate.to).toISOString()}`,
                sort: {
                    id: "desc"
                }
            })
        } else {
            setData([])
        }
    }, [accountNumber, searchDate])

    const onSearchSubmit = (e) => {
        e.preventDefault()
        loadData(pagination.rowsPerPage, 0, {...filter})
    }

    const onSort = (column, sortDir) => {
        console.log(column, sortDir);
    }

    const onPageChange = page => {
        loadData(pagination.rowsPerPage, CountPaginationOffset(page, pagination.rowsPerPage), {...filter})
        setPagination({
            ...pagination, page: page
        })
    };

    const onPaginationRowsChangePage = (newPerPage, page) => {
        loadData(newPerPage, CountPaginationOffset(page, newPerPage), {...filter})
        setPagination({
            ...pagination, page: page, rowsPerPage: newPerPage
        })
    }

    return <>
        <Col md={12}>
            {/*<Form onSubmit={onSearchSubmit}>*/}
            {/*    <Row className="justify-content-start align-items-end">*/}
            {/*        /!*<Col md="auto">*!/*/}
            {/*        /!*    <FormGroup className="row">*!/*/}
            {/*        /!*        <Label htmlFor="exampleFormControlSelect3">Cari (No Rek / Nama)</Label>*!/*/}
            {/*        /!*        <Col sm="12">*!/*/}
            {/*        /!*            <Input className="form-control" type="text" placeholder={"Search Here"}*!/*/}
            {/*        /!*                   onChange={(e) => {*!/*/}
            {/*        /!*                       setFilter({*!/*/}
            {/*        /!*                           ...filter,*!/*/}
            {/*        /!*                           account_number: parseInt(e.target.value) === NaN ? 0 : parseInt(e.target.value),*!/*/}
            {/*        /!*                           full_name: e.target.value,*!/*/}
            {/*        /!*                       })*!/*/}
            {/*        /!*                   }}/>*!/*/}
            {/*        /!*        </Col>*!/*/}
            {/*        /!*    </FormGroup>*!/*/}
            {/*        /!*</Col>*!/*/}
            {/*        <Col md="auto">*/}
            {/*            <Label htmlFor="exampleFormControlSelect3"></Label>*/}
            {/*            <FormGroup className="row">*/}
            {/*                <Btn attrBtn={{color: "primary", className: "m-r-15", type: "submit"}}>Search</Btn>*/}
            {/*            </FormGroup>*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*</Form>*/}
        </Col>

        <Col md={12}>
            <DataTable
                sortServer
                pagination
                paginationServer

                data={data}
                columns={columns}
                progressPending={isLoading}
                onSort={onSort}
                paginationTotalRows={pagination.totalRows}
                onChangeRowsPerPage={onPaginationRowsChangePage}
                onChangePage={onPageChange}
                striped={true}
                center={true}
                paginationRowsPerPageOptions={[30,60,100,1000,10000,100000,1000000]}
            />
        </Col>

        <Hidden>
            <TransactionValidationPrint ref={printRef} data={printData}/>
        </Hidden>
    </>
}