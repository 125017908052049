import React from "react";

export const TransactionValidationPrint = React.forwardRef((props, ref) => {
    let {data} = props

    const transactionType = ["N/A", "CR", "DB"]

    return <div className="transaction-validation" ref={ref}>
        <h6>{`${data.reference_number}`} {`${data.date}`}<br/>
            {`${transactionType[data.transaction_type]}   ${data.amount}   ${data.account_number}`}<br/>
            {`${data.teller_id}`}</h6>
    </div>
})