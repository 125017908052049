
import {Breadcrumbs, Btn, H4, H5} from "../../AbstractElements";
import {Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {useEffect, useState} from "react";
import {TransactionDataTable} from "../../Components/DataTables/TransactionDataTable";
import {handlerSetFormNumeric} from "../../Utils/forms";
import DatePicker from "react-datepicker";
import {parseDate} from "../../Utils/date";
import axios from "axios";
import {API_ACCOUNT, API_ACCOUNT_BALANCE} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import {Briefcase} from "react-feather";
import CurrencyFormat from 'react-currency-format';
import {BookPrintFormDrawer} from "../../Components/Drawers/BookPrintFormDrawer";

export const ViewsLaporanCetakBuku = () => {
    const initialDrawerState = {
        forms: false,
        editId: 0
    }

    const initialRefreshState = {
        transactionDataTable: false,
    }

    const [drawerState, setDrawerState] = useState(initialDrawerState)
    const [refreshState, setRefreshState] = useState(initialRefreshState)

    const closeAllDrawer = () => {
        setDrawerState(initialDrawerState)
    }

    const refreshAllState = () => {
        setRefreshState({
            ...initialRefreshState,
            transactionDataTable: true
        })
    }

    // FOR TABLE
    const [foundAccountNumber, setFoundAccountNumber] = useState(0)
    const [searchDate, setSearchDate] = useState({
        from: new Date().setHours(0, 0, 0, 0),
        to: new Date().setHours(23, 59, 59, 999)
    })

    // FOR SEARCH ACCOUNT FORM
    const initialAccountData = {
        id: 0,
        account_number: 0,
        full_name: "",
        birth_place: "",
        birth_date: new Date(2000, 0, 1, 0, 0, 0, 0).toISOString(),
        address: "",
        marriage_status: 0,
        profession: "",
        citizenship: 0,
        religion: 0
    }

    const [accountData, setAccountData] = useState(initialAccountData)
    const [currentBalance, setCurrentBalance] = useState(0)

    useEffect(() => {
        loadBalance()
    }, [foundAccountNumber])

    const handlerSubmitSearchAccount = (e) => {
        e.preventDefault()

        if (accountData.account_number === 0) {
            return
        }

        axios.get(API_ACCOUNT + "/?pagination=" + CreatePaginationEncoding(1, 0) + "&filter=" + CreateFilterEncoding({
            account_number: accountData.account_number
        })).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                json.data.length > 0 && setAccountData(json.data[0])
                setFoundAccountNumber(accountData.account_number)
            } else {
                setFoundAccountNumber(0)
                setAccountData({
                    ...initialAccountData,
                    account_number: accountData.account_number
                })
            }
        })
    }

    const loadBalance = () => {
        if (foundAccountNumber !== 0) {
            axios.get(API_ACCOUNT_BALANCE + `/${foundAccountNumber}`).then(r => {
                if (r.status === 200) {
                    return r.data["data"]
                }
            }).then(json => {
                json.balance && setCurrentBalance(json.balance)
            })
        } else {
            setCurrentBalance(0)
        }
    }

    return (
        <>
            <Breadcrumbs parent="Laporan" title="Cetak Buku Nasabah" mainTitle="Cetak Buku Nasabah"/>
            <Container fluid={true} className="datatables">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="pb-0">
                                <Row className="justify-content-between">
                                    <Col md={"auto"}>
                                        <H5>Cari Nasabah</H5>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={handlerSubmitSearchAccount}>
                                    <FormGroup>
                                        <Label>No. Rekening</Label>
                                        <Row className="align-items-center">
                                            <Col md={8} sm={12}>
                                                <Input required name="account_number" value={accountData.account_number}
                                                       onChange={(e) => handlerSetFormNumeric(e, accountData, setAccountData)}
                                                       className="form-control btn-square" type="number"
                                                       placeholder="Type Something"/>
                                            </Col>
                                            <Col md={4} sm={12}>
                                                <Btn attrBtn={{
                                                    color: 'primary',
                                                    style: {width: "100%"},
                                                    type: 'submit',
                                                }}>
                                                    Cari
                                                </Btn>
                                            </Col>
                                        </Row>
                                    </FormGroup>

                                    {
                                        accountData.full_name !== "" &&
                                        <>
                                            <Col md={12}>
                                                <Card className="o-hidden border-0">
                                                    <CardBody className={'bg-primary'}>
                                                        <div className="media static-top-widget">
                                                            <div className="align-self-center text-center">
                                                                <Briefcase/>
                                                            </div>
                                                            <div className="media-body">
                                                                <span className="m-0">Saldo</span>
                                                                <CurrencyFormat value={currentBalance}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                prefix={'Rp '} renderText={value => <H4
                                                                    attrH4={{className: 'mb-0 counter'}}>{value}</H4>}/>


                                                                <Briefcase className="icon-bg"/>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Btn attrBtn={{
                                                color: 'secondary',
                                                className: "m-b-15",
                                                style: {width: "100%"},
                                                type: 'button',
                                                onClick: () => {
                                                    setDrawerState({
                                                        ...drawerState,
                                                        forms: true
                                                    })
                                                }
                                            }}>
                                                Proses Cetak Buku
                                            </Btn>
                                            <FormGroup>
                                                <Label>Nama Lengkap</Label>
                                                <Input required name="full_name" value={accountData.full_name}
                                                       disabled={true}
                                                       className="form-control btn-square" type="text"
                                                       placeholder="Type Something"/>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Tempat Lahir</Label>
                                                <Input required name="birth_place" value={accountData.birth_place}
                                                       disabled={true}
                                                       className="form-control btn-square" type="text"
                                                       placeholder="Type Something"/>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Tanggal Lahir</Label>
                                                <DatePicker className="form-control digits"
                                                            selected={parseDate(accountData.birth_date)}
                                                            disabled={true}/>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Alamat</Label>
                                                <Input required name="address" value={accountData.address}
                                                       disabled={true}
                                                       className="form-control btn-square" type="textarea"
                                                       placeholder="Type Something"/>
                                            </FormGroup>
                                        </>
                                    }
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Container fluid={true} className="datatables">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="pb-0">
                                <Row className="justify-content-between">
                                    <Col md={"auto"}>
                                        <H5>Traksaksi Terakhir</H5>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <TransactionDataTable id={drawerState.editId} open={drawerState.forms}
                                                      onClose={closeAllDrawer} accountNumber={foundAccountNumber}
                                                      searchDate={searchDate}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <BookPrintFormDrawer open={drawerState.forms} onClose={closeAllDrawer}
                                 accountNumber={foundAccountNumber}/>
        </>
    )

}