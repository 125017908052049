
import {Breadcrumbs, H5} from "../../AbstractElements";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {useState} from "react";
import {ConfiguratorDataTable} from "../../Components/DataTables/ConfiguratorDataTable";
import {ConfiguratorFormDrawer} from "../../Components/Drawers/ConfiguratorFormDrawer";


export const ViewsSettingLainnya = () => {
    const initialDrawerState = {
        forms: false,
        editId: 0
    }

    const initialRefreshState = {
        configuratorDataTable: false,
    }

    const [drawerState, setDrawerState] = useState(initialDrawerState)
    const [refreshState, setRefreshState] = useState(initialRefreshState)

    const closeAllDrawer = () => {
        setDrawerState(initialDrawerState)
    }

    const refreshAllState = () => {
        setRefreshState({
            ...initialRefreshState,
            configuratorDataTable: true
        })
    }
    return (
        <>
            <Breadcrumbs parent="Setting" title="Setting Lainnya" mainTitle="Setting Lainnya"/>
            <Container fluid={true} className="datatables">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="pb-0">
                                <Row className="justify-content-between">
                                    <Col md={"auto"}>
                                        <H5>Setting Lainnya</H5>
                                    </Col>
                                    {/*<Col md={"auto"}>*/}
                                    {/*    <Btn attrBtn={{*/}
                                    {/*        color: "secondary", className: "m-r-15", onClick: (e) => {*/}
                                    {/*            setDrawerState({...drawerState, forms: true})*/}
                                    {/*        }*/}
                                    {/*    }}>Nasabah Baru</Btn>*/}
                                    {/*</Col>*/}
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <ConfiguratorDataTable drawerState={drawerState} setDrawerState={setDrawerState}
                                                       refresh={refreshState.configuratorDataTable}
                                                       onRefreshFinish={() => setRefreshState(initialRefreshState)}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ConfiguratorFormDrawer id={drawerState.editId} open={drawerState.forms} onClose={closeAllDrawer}/>
        </>
    )

}