
import {ViewsManageNasabah} from "../Views/ManageNasabah";
import {ViewsLaporanMutasi} from "../Views/ManageNasabahMutasi";
import {ViewsTransaksiSimpan} from "../Views/TransaksiSimpan";
import {ViewsTransaksiAmbil} from "../Views/TransaksiAmbil";
import {ViewsLaporanCetakBuku} from "../Views/ManageNasabahCetakBuku";
import {ViewsLaporanTransaksiNasabah} from "../Views/LaporanTransaksi";
import ViewsSettingAkun from "../Views/SettingAkun";
import ViewsLogin from "../Views/Login";
import {ViewsSettingPengguna} from "../Views/SettingPengguna";
import {ViewsSettingLainnya} from "../Views/SettingLainnya";
import {ViewsLaporanRekapKeuangan} from "../Views/LaporanRekapKeuangan";

export const routes = [
    {path: `${process.env.PUBLIC_URL}/transaction/debit`, Component: <ViewsTransaksiSimpan/>},
    {path: `${process.env.PUBLIC_URL}/transaction/credit`, Component: <ViewsTransaksiAmbil/>},
    {path: `${process.env.PUBLIC_URL}/customer/account`, Component: <ViewsManageNasabah/>},
    // {path: `${process.env.PUBLIC_URL}/report/transaction`, Component: <ViewsLaporanTransaksi/>},
    {path: `${process.env.PUBLIC_URL}/report/account-transaction`, Component: <ViewsLaporanTransaksiNasabah/>},
    {path: `${process.env.PUBLIC_URL}/report/mutation`, Component: <ViewsLaporanMutasi/>},
    {path: `${process.env.PUBLIC_URL}/report/book-print`, Component: <ViewsLaporanCetakBuku/>},
    {path: `${process.env.PUBLIC_URL}/report/funds`, Component: <ViewsLaporanRekapKeuangan/>},
    {path: `${process.env.PUBLIC_URL}/setting/account`, Component: <ViewsSettingAkun/>},
    {path: `${process.env.PUBLIC_URL}/setting/users`, Component: <ViewsSettingPengguna/>},
    {path: `${process.env.PUBLIC_URL}/setting/others`, Component: <ViewsSettingLainnya/>},
];


export const unprotectedRoutes = [
    {path: `${process.env.PUBLIC_URL}/login`, Component: <ViewsLogin/>},
]
