import Drawer from "react-modern-drawer";
import {useEffect, useRef, useState} from "react";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Btn, H4} from "../../AbstractElements";
import {handlerSetFormString} from "../../Utils/forms";
import axios from "axios";
import SweetAlert from "sweetalert2";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import {API_ACCOUNT} from "../../api/service";
import {ReligionSelect} from "../Select/ReligionSelect";
import {MarriageStatusSelect} from "../Select/MarriageStatusSelect";
import {CitizenshipSelect} from "../Select/CitizenshipSelect";
import DatePicker from "react-datepicker";
import {parseDate} from "../../Utils/date";
import {GenderSelect} from "../Select/GenderSelect";
import {Hidden} from "../Common/Component/Hidden";
import {BookCoverPrint} from "../Print/BookCoverPrint";
import {useReactToPrint} from "react-to-print";
import {AccountTypeSelect} from "../Select/AccountTypeSelect";

export const NasabahFormDrawer = ({id, open, onClose, direction = "right", className}) => {
    const initialData = {
        id: 0,
        account_number: 0,
        full_name: "",
        birth_place: "",
        birth_date: new Date(2000, 0, 1, 0, 0, 0, 0).toISOString(),
        address: "",
        marriage_status: 0,
        profession: "",
        citizenship: 0,
        religion: 0,
        identity_number: "",
        contact: "",
        gender: 1,
        account_type: 1,
    }
    const [data, setData] = useState(initialData)

    const printRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        pageStyle: `
        @page {
            size: portrait;
          }
        `
    });

    const [printData, setPrintData] = useState({
        office_name: "N/A",
        created_at: "N/A",
        account_number: "N/A",
        full_name: "N/A",
        address: "N/A",
    })

    useEffect(() => {
        if (id !== 0) {
            axios.get(API_ACCOUNT + "/?pagination=" + CreatePaginationEncoding(1, 0) + "&filter=" + CreateFilterEncoding({
                id: id
            })).then(r => {
                if (r.status === 200) {
                    return r.data["data"]
                }
            }).then(json => {
                if (json.data) {
                    json.data.length > 0 && setData(json.data[0])
                    json.data.length > 0 && setPrintData({
                        office_name: "Kantor Safara Kraksaan",
                        created_at: `${json.data[0]['created_at'] && parseDate(json.data[0]['created_at']).toLocaleString("en-GB", {timeZone: "Asia/Jakarta"})}`,
                        account_number: `${json.data[0]['account_number']}`,
                        full_name: `${json.data[0]['full_name']}`,
                        address: `${json.data[0]['address']}`,
                    })
                }
            })
        } else {
            setData(initialData)
        }
    }, [id])

    const handlerSubmit = (e) => {
        e.preventDefault()
        if (id === 0) {
            axios.post(API_ACCOUNT + "/", {...data}).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({
                        title: 'Success!',
                        text: 'Data Saving Completed!',
                        icon: 'success'
                    }).then(() => {
                        onClose()
                    })
                } else {
                    throw r
                }
            }).catch((err) => {
                SweetAlert.fire({
                    title: 'Failed!',
                    text: 'Data Saving Failed!',
                    icon: 'error'
                })
            })
        } else {
            axios.put(API_ACCOUNT + "/", {...data, id: id}).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({
                        title: 'Success!',
                        text: 'Data Saving Completed!',
                        icon: 'success'
                    }).then(() => {
                        onClose()
                    })
                } else {
                    throw r
                }
            }).catch((err) => {
                SweetAlert.fire({
                    title: 'Failed!',
                    text: 'Data Saving Failed!',
                    icon: 'error'
                })
            })
        }

    }

    return (
        <Drawer
            open={open}
            onClose={onClose}
            direction={direction}
            className={'creation-drawer ' + className}
        >
            <div className="m-b-30">
                <H4>{id !== 0 ? "Edit" : "Buat"} Nasabah</H4>
                <hr/>
            </div>
            <Form className="form theme-form" onSubmit={handlerSubmit}>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Jenis Tabungan</Label>
                            <AccountTypeSelect selected={data.account_type} onChange={(sOpt) => {
                                setData({
                                    ...data,
                                    account_type: sOpt.value
                                })
                            }}/>
                        </FormGroup>
                        <hr/>
                        <FormGroup>
                            <Label>Nomor Identitas</Label>
                            <Input required name="identity_number" value={data.identity_number}
                                   onChange={(e) => handlerSetFormString(e, data, setData)}
                                   className="form-control btn-square" type="text"
                                   placeholder="Type Something"/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Nama Lengkap</Label>
                            <Input required name="full_name" value={data.full_name}
                                   onChange={(e) => handlerSetFormString(e, data, setData)}
                                   className="form-control btn-square" type="text"
                                   placeholder="Type Something"/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Tempat Lahir</Label>
                            <Input required name="birth_place" value={data.birth_place}
                                   onChange={(e) => handlerSetFormString(e, data, setData)}
                                   className="form-control btn-square" type="text"
                                   placeholder="Type Something"/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Tanggal Lahir</Label>
                            <DatePicker className="form-control digits" selected={parseDate(data.birth_date)}
                                        onChange={(e) => {
                                            setData({
                                                ...data,
                                                birth_date: e.toISOString()
                                            })
                                        }}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Alamat</Label>
                            <Input required name="address" value={data.address}
                                   onChange={(e) => handlerSetFormString(e, data, setData)}
                                   className="form-control btn-square" type="textarea"
                                   placeholder="Type Something"/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Jenis Kelamin</Label>
                            <GenderSelect selected={data.gender} onChange={(sOpt) => {
                                setData({
                                    ...data,
                                    gender: sOpt.value
                                })
                            }}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Status Perkawinan</Label>
                            <MarriageStatusSelect selected={data.marriage_status} onChange={(sOpt) => {
                                setData({
                                    ...data,
                                    marriage_status: sOpt.value
                                })
                            }}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Pekerjaan</Label>
                            <Input required name="profession" value={data.profession}
                                   onChange={(e) => handlerSetFormString(e, data, setData)}
                                   className="form-control btn-square" type="text"
                                   placeholder="Type Something"/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Kewarganegaraan</Label>
                            <CitizenshipSelect selected={data.citizenship} onChange={(sOpt) => {
                                setData({
                                    ...data,
                                    citizenship: sOpt.value
                                })
                            }}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Agama</Label>
                            <ReligionSelect selected={data.religion} onChange={(sOpt) => {
                                setData({
                                    ...data,
                                    religion: sOpt.value
                                })
                            }}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Kontak</Label>
                            <Input required name="contact" value={data.contact}
                                   onChange={(e) => handlerSetFormString(e, data, setData)}
                                   className="form-control btn-square" type="text"
                                   placeholder="Type Something"/>
                        </FormGroup>
                    </Col>
                </Row>
                <Btn attrBtn={{
                    color: 'primary',
                    className: 'm-r-15',
                    type: 'submit',
                }}
                >Submit</Btn>
                {id !== 0 &&
                    <Btn attrBtn={{
                        color: 'secondary',
                        onClick: () => handlePrint(),
                        className: 'm-r-15',
                        type: "button"
                    }}>Cetak
                        Cover Buku</Btn>}
                <Btn attrBtn={{color: 'light', onClick: () => onClose()}}>Cancel</Btn>
            </Form>

            <Hidden>
                <BookCoverPrint ref={printRef} data={printData}/>
            </Hidden>
        </Drawer>
    )
}