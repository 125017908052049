import React, {useEffect, useState} from "react";
import {parseDate} from "../../Utils/date";

export const TransactionBookReportPrint = React.forwardRef((props, ref) => {
    let {data, startingLine} = props

    const [processedData, setProcessedData] = useState([])

    useEffect(() => {
        const arrFiller = Array(startingLine).fill({
            account_number: null,
            amount: null,
            balance: null,
            id: null,
            transaction_date: null,
            transaction_detail_code: null,
            transaction_type: null,
        })

        const fullData = [...arrFiller, ...data]

        setProcessedData(sliceIntoChunks(fullData, 25))

    }, [])

    useEffect(() => {
        const arrFiller = Array(startingLine).fill({
            account_number: null,
            amount: null,
            balance: null,
            id: null,
            transaction_date: null,
            transaction_detail_code: null,
            transaction_type: null,
        })

        const fullData = [...arrFiller, ...data]

        setProcessedData(sliceIntoChunks(fullData, 25))

    }, [data])


    const sliceIntoChunks = (arr, chunkSize) => {
        const res = []
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk)
        }
        return res
    }

    return (
        <div className="transaction-book-print" ref={ref}>
            {processedData.map((chunk) => {
                return <table>
                    {
                        chunk.map((v) => {
                            return (
                                <tr>
                                    <td style={{
                                        width: "1.917cm",
                                    }}>{v.transaction_date && parseDate(v.transaction_date).toLocaleDateString('en-GB', {timeZone: 'Asia/Jakarta'})}
                                    </td>
                                    <td style={{
                                        width: "3cm",
                                    }}>{v.transaction_type && (v.transaction_type === 1 ? v.amount.toLocaleString('en-US', {maximumFractionDigits: 2}) : "")}
                                    </td>
                                    <td style={{
                                        width: "3.233cm",
                                    }}>{v.transaction_type && (v.transaction_type === 2 ? v.amount.toLocaleString('en-US', {maximumFractionDigits: 2}) : "")}
                                    </td>
                                    <td style={{
                                        width: "3.233cm",
                                    }}>{v.balance !== null && v.balance.toLocaleString('en-US', {maximumFractionDigits: 2})}
                                    </td>
                                    <td style={{
                                        width: "2.1cm",
                                    }}>{v.id !== null && v.id}
                                    </td>
                                </tr>
                            )
                        })}

                </table>

            })}


        </div>
    )
})