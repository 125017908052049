import React, {Fragment} from 'react';
import {Card} from 'reactstrap';
import {Btn, LI} from '../../../AbstractElements';
import {LogOut} from 'react-feather';
import {Link, useNavigate} from 'react-router-dom';
import {LS_USER_KEY} from "../../../Utils/jwt";
import {useCookies} from "react-cookie";

const LogoutClass = () => {
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies();

    const Logout = () => {
        localStorage.removeItem(LS_USER_KEY);
        removeCookie('XJ-AUTH', {path: '/'});

        navigate(`${process.env.PUBLIC_URL}/login`)
    };

    return (
        <Fragment>
            <LI attrLI={{className: 'onhover-dropdown p-0', onClick: Logout}}>
                <Btn attrBtn={{as: Card.Header, className: 'btn btn-primary-light', color: 'default'}}>
                    <Link to={`${process.env.PUBLIC_URL}/login`}>
                        <LogOut/>
                        Log out
                    </Link>
                </Btn>
            </LI>
        </Fragment>
    );
};

export default LogoutClass;