import {Book, Download, Settings, Upload, User, Users} from 'react-feather';

export const MENUITEMS = [
    {
        menutitle: 'Transaksi',
        menucontent: 'simpan,ambil',
        Items: [
            {
                title: 'Simpan', icon: Download, type: 'link', path: `${process.env.PUBLIC_URL}/transaction/debit`
            },
            {
                title: 'Ambil', icon: Upload, type: 'link', path: `${process.env.PUBLIC_URL}/transaction/credit`
            },
        ]
    },
    {
        menutitle: 'Nasabah',
        menucontent: 'manage nasabah',
        Items: [
            {
                title: 'Manage Nasabah',
                icon: User,
                type: 'link',
                path: `${process.env.PUBLIC_URL}/customer/account`
            },
            {
                title: 'Mutasi', icon: Book, type: 'link', path: `${process.env.PUBLIC_URL}/report/mutation`
            },
            {
                title: 'Cetak Buku', icon: Book, type: 'link', path: `${process.env.PUBLIC_URL}/report/book-print`
            },
        ]
    },
    {
        menutitle: 'Laporan',
        menucontent: 'transaksi',
        Items: [
            {
                title: 'Transaksi Nasabah', icon: Book, type: 'link', path: `${process.env.PUBLIC_URL}/report/account-transaction`
            },
            {
                title: 'Rekap Keuangan', icon: Book, type: 'link', path: `${process.env.PUBLIC_URL}/report/funds`
            },
        ]
    },
    {
        menutitle: 'Setting',
        menucontent: 'akun, pengguna, others',
        Items: [
            {
                title: 'Akun', icon: User, type: 'link', path: `${process.env.PUBLIC_URL}/setting/account`
            },
            {
                title: 'Pengguna', icon: Users, type: 'link', path: `${process.env.PUBLIC_URL}/setting/users`
            },
            {
                title: 'Lainnya', icon: Settings, type: 'link', path: `${process.env.PUBLIC_URL}/setting/others`
            },
        ]
    }
]