import Drawer from "react-modern-drawer";
import {useEffect, useState} from "react";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Btn, H4} from "../../AbstractElements";
import {handlerSetFormString} from "../../Utils/forms";
import axios from "axios";
import SweetAlert from "sweetalert2";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import {API_CONFIGURATOR} from "../../api/service";

export const ConfiguratorFormDrawer = ({id, open, onClose, direction = "right", className}) => {
    const initialData = {
        id: 0,
        account_number: 0,
        full_name: "",
        birth_place: "",
        birth_date: new Date(2000, 0, 1, 0, 0, 0, 0).toISOString(),
        address: "",
        marriage_status: 0,
        profession: "",
        citizenship: 0,
        religion: 0
    }
    const [data, setData] = useState(initialData)

    useEffect(() => {
        if (id !== 0) {
            axios.get(API_CONFIGURATOR + "/?pagination=" + CreatePaginationEncoding(1, 0) + "&filter=" + CreateFilterEncoding({
                id: id
            })).then(r => {
                if (r.status === 200) {
                    return r.data["data"]
                }
            }).then(json => {
                if (json.data) {
                    json.data.length > 0 && setData(json.data[0])
                }
            })
        } else {
            setData(initialData)
        }
    }, [id])

    const handlerSubmit = (e) => {
        e.preventDefault()
        if (id === 0) {
            axios.post(API_CONFIGURATOR + "/", {...data}).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({
                        title: 'Success!',
                        text: 'Data Saving Completed!',
                        icon: 'success'
                    }).then(() => {
                        onClose()
                    })
                } else {
                    throw r
                }
            }).catch((err) => {
                SweetAlert.fire({
                    title: 'Failed!',
                    text: 'Data Saving Failed!',
                    icon: 'error'
                })
            })
        } else {
            axios.put(API_CONFIGURATOR + "/", {...data, id: id}).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({
                        title: 'Success!',
                        text: 'Data Saving Completed!',
                        icon: 'success'
                    }).then(() => {
                        onClose()
                    })
                } else {
                    throw r
                }
            }).catch((err) => {
                SweetAlert.fire({
                    title: 'Failed!',
                    text: 'Data Saving Failed!',
                    icon: 'error'
                })
            })
        }

    }

    return (
        <Drawer
            open={open}
            onClose={onClose}
            direction={direction}
            className={'creation-drawer ' + className}
        >
            <div className="m-b-30">
                <H4>{id !== 0 ? "Edit" : "Buat"} Nasabah</H4>
                <hr/>
            </div>
            <Form className="form theme-form" onSubmit={handlerSubmit}>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Value</Label>
                            <Input required name="value" value={data.value}
                                   onChange={(e) => handlerSetFormString(e, data, setData)}
                                   className="form-control btn-square" type="textarea"
                                   placeholder="Type Something"/>
                        </FormGroup>
                    </Col>
                </Row>
                <Btn attrBtn={{
                    color: 'primary',
                    className: 'm-r-15',
                    type: 'submit',
                }}
                >Submit</Btn>
                <Btn attrBtn={{color: 'light', onClick: () => onClose()}}>Cancel</Btn>
            </Form>
        </Drawer>
    )
}